.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.navigation .logo-container {
    height: 100%;
    width: 70px;
    padding: 25px;
}

.navigation .nav-links-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navigation .nav-links-container .nav-link {
    padding: 10px 15px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: Black;
}

* {
    box-sizing: border-box;
}

.flag > * {
    width: 100%;
    height: 100%;
}