@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-light {
    background-color: white !important;
}

.btn-primary {
    background: #d08926 !important;
    border: 1px solid #d08926 !important;
}

.hide-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.progress-bar {
    background: #d08926 !important;
}

.__react_modal_image__header {
    padding: 8px 0;
    background: transparent;
}

.text-red {
    color: red;
}

.css-13cymwt-control {
    min-height: 45px !important;
}

@media screen and (max-width: 1024px) {
    #donate-icon {
        width: 28px !important;
        aspect-ratio: 1;
    }
}
